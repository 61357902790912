import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import ClickWall from '../components/sections/click-wall'
import ShopPhone from '../components/sections/shop-phone'

const Rates = ({ location }) => (
  <Layout phone="866-740-2901" location={location} footerAlt hideCta>
    <SEO
      title="Medicare Offers"
      keywords={[`medicare`, `health`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone="866-740-2901" />
    <Intro
      classNames="title-content-bg"
      colType="full-col"
      title="We Matched You With These Top Insurance Companies"
      subTitle="Click at least 2 to 3 companies below to find the best rate!"
    />
    <main id="main">
      <ClickWall hideGen />
      <ShopPhone phone="866-740-2901" />
    </main>
  </Layout>
)

export default Rates
