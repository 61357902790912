import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { Helmet } from 'react-helmet'
// import { Portal } from 'react-portal'
import clsx from 'classnames'
import get from 'lodash/get'
import axios from 'axios'
import padStart from 'lodash/padStart'

import { getOffterId } from 'utils/clickwall'

// import AddConnectStreams from './ConnectStreams'

import ClickWallBanner from './banner'

// import { formatPhoneNumber } from '../../../api/apply'

import './click-wall.scss'

// const placement = type =>
//   type === 'health'
//     ? '513a9d87-84ad-4eb5-90b7-9e2f532ea581'
//     : '674f9278-069e-41e1-ad37-cb18f3dbcd14'
// const phone = phone => formatPhoneNumber(phone)

const tel = '866-740-2901'
const addBanner = (listings) =>
  listings?.length
    ? listings.length > 3
      ? [...listings.slice(0, 3), 'banner', ...listings.slice(3)]
      : [...listings, 'banner']
    : ['banner']

export const ClickWall = ({ captive, overrideOfferId = false }) => {
  const [listingsData, setListingsData] = useState(['banner'])
  const [listingsHeader, setListingsHeader] = useState(null)

  const phoneNumber = get(captive, 'data.phone', '')

  const fetchUserIP = async () => {
    return await axios
      .get('https://pro.ip-api.com/json/?key=g9gkAqhucbYIbxb')
      .then((res) => {
        return res?.data?.query
      })
      .catch((err) => {
        console.log(err)
        return ''
      })
  }

  const fetchListing = async (ip) => {
    const formatPhoneNumber = (phoneNumberString) => {
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3]
      }
      return phoneNumberString
    }

    const campaign = get(captive, 'campaign', 'organic')
    const zip = get(captive, 'data.zip', '')
    const year = get(captive, 'data.year', '')
    const month = get(captive, 'data.month', '')
    const day = get(captive, 'data.day', '')
    const type = get(captive, 'type', 'medicare')

    const first = get(captive, 'data.first', '')
    const phone = get(captive, 'data.phone', '')
    const address = get(captive, 'data.address', '')
    const last = get(captive, 'data.last', '')
    const email = get(captive, 'data.email', '')
    const gender = get(captive, 'data.gender', '')

    const ni_ad_client = overrideOfferId ? overrideOfferId : getOffterId(campaign, type)

    await axios
      .post(
        `${
          process.env.NODE_ENV === 'development'
            ? 'https://nextinsure.quinstage.com/listingdisplay/listings/'
            : 'https://www.nextinsure.com/listingdisplay/listings'
        }`,
        {
          tracking: {
            content_type: 'json',
            ni_ad_client,
            ip,
            ua: navigator.userAgent,
            ni_ref: 'medicarecompanion.org',
            ni_zc: zip,
            ni_var1: campaign,
            dob: `${year}-${padStart(month, 2, 0)}-${padStart(day, 2, 0)}`,
          },
          contact: {
            first_name: first,
            last_name: last,
            email,
            home_phone: formatPhoneNumber(phone),
            address,
            zip,
          },
          individuals: [
            {
              first_name: first,
              last_name: last,
              gender: gender === 'male' ? 'M' : 'F',
              birthdate: `${year}-${padStart(month, 2, 0)}-${padStart(day, 2, 0)}`,
            },
          ],
        },
      )
      .then((res) => {
        const responseData = res.data.response.listingset
        setListingsHeader(responseData.header)
        setListingsData(addBanner(responseData.listing))
      })
  }

  useEffect(() => {
    fetchUserIP().then((ip) => {
      fetchListing(ip)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx('container', 'clickwall')}>
      {/* <AddConnectStreams phoneNumber={phoneNumber} /> */}
      <section>
        {listingsHeader && listingsHeader !== '' && (
          <h2 className="t-heading2 mb4">{listingsHeader}</h2>
        )}
        {listingsData.map((listing, index) => {
          if (listing === 'empty') return <div key={index} />
          if (listing === 'banner') return <ClickWallBanner key={listing} tel={tel} />
          return (
            <div key={listing.accountid + listing.customerid}>
              <a
                className={clsx(
                  'clickwall__listing',
                  index <= 2 && 'clickwall__listing--top',
                )}
                href={listing.clickurl}
                target="__blank"
              >
                <div className="clickwall__listing__logo">
                  <img src={listing.logo} alt=" " />
                </div>
                <div className="clickwall__listing__middle">
                  <h4 className="t-heading4">{listing.displayname}</h4>
                  <p className="">{listing.title}</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: listing.description }}
                    className="clickwall__listing__middle__description"
                  />
                </div>
                <a
                  href={listing.clickurl}
                  className="btn clickwall__listing__getQuotes"
                  target="__blank"
                >
                  {index > 2 ? 'Get Quotes' : 'Lock In Rates!'}
                </a>
              </a>
              <img src={listing.imp_pixel} alt=" " />
            </div>
          )
        })}
      </section>
    </div>
  )
}

export default connect((state) => ({
  captive: state.captive,
}))(ClickWall)
