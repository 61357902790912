import React from 'react'

import phoneImage from '../../../images/phone-woman.png'

const ClickWallBanner = ({ tel }) => (
  <div className="clickwall__banner">
    <div className="clickwall__banner__image">
      <img src={phoneImage} alt=" " />
    </div>
    <div className="clickwall__banner__middle">
      <h2 className="t-teal">
        Give us a call if you have any questions <br />
        you can talk to a licensed agent
      </h2>
      <p>your wait time is less than {Math.floor(Math.random() * 10) + 1} seconds</p>
      <div className="clickwall__banner__cta">
        <span className="clickwall__banner__indicator" />
        <a href={`tel:${tel}`} className="btn with-icon">
          <span className="icon-phone" />
          <span className="text">{tel}</span>
        </a>
      </div>
    </div>
  </div>
)

export default React.memo(ClickWallBanner)
